import { useCallback } from "react";
import { QuestWithId } from "../../models/api/quest";
import useUpdateQuestMetaDataAndNodeData from "./useUpdateQuestMetaDataAndNodeData";

export default function useArchiveQuest(questId: string, quest?: QuestWithId) {
  const { isUpdating: isArchiving, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const archiveQuest = useCallback(() => {
    if (quest == null) {
      return;
    }

    const { data } = quest;
    const nodes = data?.nodes ?? [];
    const edges = data?.edges ?? [];

    updateQuestMetaDataAndNodeData({
      questId,
      partialQuest: { isArchived: true },
      questData: { nodes, edges },
    });
  }, [questId, quest]);

  return {
    isArchiving,
    archiveQuest,
  };
}
