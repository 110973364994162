import { AuthApi } from "@worldwidewebb/client-auth";
import { Profile, ProfileApi } from "@worldwidewebb/client-profiles";
import { axiosInstance, buildApiClientBasePath } from "..";
import { SessionProfile } from "../../models/users/sessionProfile";
import { getRandomColor } from "../../utils/getRandomColor";

const profileApi = new ProfileApi(undefined, buildApiClientBasePath("profiles"), axiosInstance);

export async function getProfiles(searchQuery: string): Promise<Profile[]> {
  try {
    const { data: profiles } = await profileApi.searchUserProfiles(searchQuery);

    return profiles;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getProfile(userId?: string): Promise<Profile | null> {
  if (userId == null) {
    return null;
  }

  try {
    const { data: profile } = await profileApi.getProfile(userId);

    return profile;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function hasProfile(userId: string): Promise<boolean> {
  try {
    await profileApi.getProfile(userId);

    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const authApi = new AuthApi(undefined, buildApiClientBasePath("auth"), axiosInstance);

export async function getSessionUserId(): Promise<string> {
  try {
    const { data: sessions } = await authApi.getUserSessions();
    const [{ userId }] = sessions;

    return userId;
  } catch (error) {
    console.error(error);
    return "";
  }
}

export async function getSessionProfile(): Promise<SessionProfile> {
  try {
    const sessionUserId = await getSessionUserId();

    const { data: profile } = await profileApi.getProfile(sessionUserId);

    return {
      ...profile,
      displayNameColor: getRandomColor(),
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
