const colors = [
  "green.600",
  "red.600",
  "orange.600",
  "yellow.200",
  "teal.400",
  "blue.400",
  "cyan.400",
  "purple.400",
  "pink.400",
];

export const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];
