import { useQuery } from "@tanstack/react-query";
import { getSessionProfile } from "../../api/users/users";
import { SessionProfile } from "../../models/users/sessionProfile";

export default function useSessionProfile() {
  const {
    isLoading,
    isFetching,
    error,
    data: sessionProfile,
  } = useQuery<SessionProfile>({
    queryKey: ["sessionProfile"],
    queryFn: () => getSessionProfile(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    sessionProfile,
  };
}
