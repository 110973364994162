import React from "react";
import { User } from "../hooks/useYDoc";
import { Card, Flex, HStack, Icon, List, ListItem, Text } from "@chakra-ui/react";
import { MdRemoveRedEye } from "react-icons/md";

export interface UserListProps {
  users: User[];
}

export const UserList: React.FC<UserListProps> = ({ users }) => {
  return (
    <Flex position={"absolute"} right={20} top={"9.5rem"}>
      <Card p={1} bg={"theme.dark.background"}>
        <List>
          {users.map(({ username, color, isCurrentUser }, index) => (
            <ListItem key={index} p={1}>
              <HStack
                alignItems={"center"}
                px={2}
                py={1}
                borderWidth={isCurrentUser ? 1 : undefined}
                borderColor={isCurrentUser ? color : undefined}
                borderRadius={5}
              >
                <Icon color={color} as={MdRemoveRedEye} />
                <Text color={color} fontWeight={400}>
                  {username.toUpperCase()}
                </Text>
              </HStack>
            </ListItem>
          ))}
        </List>
      </Card>
    </Flex>
  );
};
