import { memo } from "react";
import { Card, Divider, Flex, HStack } from "@chakra-ui/react";
import QuestsSaveButton from "../../features/quests/QuestsSaveButton";
import QuestsFilter from "../../features/quests/QuestsFilter";
import QuestsArchiveButton from "../../features/quests/QuestsArchiveButton";

function QuestsToolbar() {
  return (
    <HStack position={"fixed"} right={20} top={4}>
      <Card p={1} bg={"theme.dark.background"}>
        <Flex gap={1} align={"center"}>
          <QuestsArchiveButton />

          <QuestsFilter />

          <Divider orientation={"vertical"} h={8} />

          <QuestsSaveButton />
        </Flex>
      </Card>
    </HStack>
  );
}

export default memo(QuestsToolbar);
