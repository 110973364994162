import { useCallback } from "react";
import { json, LoaderFunctionArgs } from "react-router";
import { AxiosError } from "axios";
import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import { getSessionProfile } from "../../api/users/users";

const useQuestLoader = (getSubgraphs: () => Promise<QuestSubgraphWithId[]>) => {
  const loader = useCallback(
    async ({ params: { id: questId } }: LoaderFunctionArgs) => {
      if (!questId) {
        throw json(
          {
            message: "Error occurred with quest identifier...",
          },
          {
            status: 400,
          }
        );
      }

      const sessionProfile = await getSessionProfile();

      try {
        return {
          sessionProfile,
          subgraphs: await getSubgraphs(),
        };
      } catch (error) {
        console.error(error);

        if (error instanceof AxiosError) {
          throw json(
            {
              message: error.response?.data.message,
              details: error.response?.data.details,
            },
            {
              status: error.response?.data.message === "Unauthorized" ? 401 : error.status,
            }
          );
        }

        throw json(
          {
            message: "Error occurred fetching quest...",
          },
          {
            status: 500,
          }
        );
      }
    },
    [getSubgraphs]
  );

  return { loader };
};

export default useQuestLoader;
