import {
  QuestEditorApi,
  QuestMetadataWithQuestId,
  PickQuestMetadataWithQuestIdExcludeKeyofQuestMetadataWithQuestIdQuestEditorDataOrQuestEditorDataDefinitions as QuestMetadataWithQuestIdWithoutData,
  QuestNode,
  QuestApi,
} from "@worldwidewebb/client-quests";
import { Edge, Node } from "reactflow";
import { ulid } from "ulid";
import { axiosInstance, buildQuestClientBasePath } from "..";
import { StartConditionKind } from "../../components/quests/QuestUpdateTargetModal";
import { QuestData, QuestWithId } from "../../models/api/quest";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";
import { StartConditionType, StartNodeData } from "../../store/quests";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getQuests(displayName?: string, isArchived?: boolean): Promise<QuestWithId[]> {
  try {
    const { data: quests } = await questEditorApi.getQuestEditorQuests(displayName, undefined, isArchived);

    return quests
      .sort(({ displayName: a }, { displayName: b }) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map(mapQuestFromApiWithoutData);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getQuest(questId: string): Promise<QuestWithId> {
  try {
    const { data: quest } = await questEditorApi.getQuestEditorQuest(questId);

    return mapQuestFromApi(quest);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getQuestOrNull(questId: string | null): Promise<QuestWithId | null> {
  if (questId == null) {
    return null;
  }

  return await getQuest(questId);
}

export async function createQuest(name: string) {
  const quest: QuestWithId = {
    questId: ulid(),
    version: ulid(),
    name,
    description: "",
    data: {
      nodes: [],
      edges: [],
    },
    status: "draft",
    isReady: false,
    isTemplate: false,
    folderId: "",
    authorId: "",
    priority: 0,
    repeatPeriod: "none",
    questNpcId: "",
    updatedAt: new Date().toISOString(),
  };

  return await setQuestMetaDataAndNodeData(quest, []);
}

export type CreateDuplicates = (
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[]
) => Promise<{ nodes: Node<NodeType>[]; edges: Edge<EdgeType>[] }>;

export type ExportQuestNodes = (nodes: Node<NodeType>[], edges: Edge<EdgeType>[], nodeTypes: NodeType[]) => QuestNode[];

export async function duplicateQuest(
  questId: string,
  createDuplicates: CreateDuplicates,
  exportQuestNodes: ExportQuestNodes,
  nodeTypes: NodeType[]
) {
  const quest = await getQuest(questId);

  const { name, data: { nodes, edges } = { nodes: [], edges: [] } } = quest;

  const { nodes: duplicatedNodes, edges: duplicatedEdges } = await createDuplicates(nodes, edges);

  const updatedQuest: QuestWithId = {
    ...quest,
    questId: ulid(),
    version: ulid(),
    name: `${name} (duplicate)`,
    data: {
      nodes: duplicatedNodes,
      edges: duplicatedEdges,
    },
    isReady: false,
    status: "draft",
  };

  const updatedNodes = exportQuestNodes(duplicatedNodes, duplicatedEdges, nodeTypes);

  return await setQuestMetaDataAndNodeData(updatedQuest, updatedNodes);
}

export type DeployEnvironment = "dev" | "stage" | "prod";

async function getQuestDeployAuth() {
  try {
    const { data } = await questEditorApi.getQuestDeployAuth();

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deployQuest(
  questId: string,
  environment: DeployEnvironment,
  exportQuestNodes: ExportQuestNodes,
  nodeTypes: NodeType[]
) {
  let questClientBasePath = buildQuestClientBasePath();

  switch (environment) {
    case "dev": {
      questClientBasePath = "https://quest-editor.apps.webb.party/api/quest-editor";

      break;
    }
    case "stage": {
      questClientBasePath = "https://quest-editor.apps.webb.black/api/quest-editor";

      break;
    }
    case "prod": {
      questClientBasePath = "https://quest-editor.apps.webb.game/api/quest-editor";

      break;
    }
  }

  const questEditorApi = new QuestEditorApi(undefined, questClientBasePath, axiosInstance);

  try {
    const quest = await getQuest(questId);
    const { data } = quest;

    const questData = typeof data === "string" ? JSON.parse(data) : data;

    await questEditorApi.setQuest(
      quest.questId,
      {
        metadata: mapQuestToApi(quest),
        nodes: exportQuestNodes(questData.nodes, questData.edges, nodeTypes),
      },
      {
        auth: await getQuestDeployAuth(),
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateQuestMetaDatas(quests: Pick<QuestWithId, "questId" | "questEditorPosition">[]) {
  try {
    await questEditorApi.updateQuestEditorQuestMetadatas(quests);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateQuestMetaData(questId: string, partialQuest: Partial<QuestWithId>) {
  const currentQuest = await getQuest(questId);
  const updatedQuest = { ...currentQuest, ...partialQuest };

  return await setQuestMetaData(updatedQuest);
}

export async function updateQuestMetaDataAndNodeData(
  questId: string,
  partialQuest: Partial<QuestWithId>,
  questData: QuestData,
  nodeTypes: NodeType[],
  exportQuestNodes: ExportQuestNodes
) {
  const currentQuest = await getQuest(questId);
  const partialNodes = { data: questData };
  const updatedQuest = { ...currentQuest, ...partialNodes, ...partialQuest, version: ulid() };

  const updatedQuestNodes = exportQuestNodes(questData.nodes, questData.edges, nodeTypes);

  return await setQuestMetaDataAndNodeData(updatedQuest, updatedQuestNodes);
}

export async function updateQuestWithStartCondition<T>(
  questId: string,
  startConditionNodeId: string,
  startConditionKind: StartConditionKind,
  startConditionType: StartConditionType,
  value: T,
  nodeTypes: NodeType[],
  exportQuestNodes: ExportQuestNodes
) {
  const currentQuest = await getQuest(questId);
  const updatedQuest = { ...currentQuest, version: ulid() };

  const currentNodes = currentQuest.data?.nodes ?? [];
  const currentEdges = currentQuest.data?.edges ?? [];

  const updatedNodes = currentNodes.map((node) => {
    if (node.id !== startConditionNodeId) {
      return node;
    }

    const startConditionNode: Node<NodeType<StartNodeData>> = node;

    startConditionNode.data.nodeData[startConditionKind === "all" ? "allStartConditions" : "anyStartConditions"].push({
      id: ulid(),
      type: startConditionType,
      value,
    });

    return node;
  });

  const updatedEdges = currentEdges;

  const updatedQuestNodes = exportQuestNodes(updatedNodes, updatedEdges, nodeTypes);

  return await setQuestMetaDataAndNodeData(updatedQuest, updatedQuestNodes);
}

async function setQuestMetaData(quest: QuestWithId): Promise<QuestWithId> {
  try {
    const { data } = await questEditorApi.updateQuestEditorQuestMetadata(quest.questId, mapQuestToApi(quest));

    return mapQuestFromApi(data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function setQuestMetaDataAndNodeData(quest: QuestWithId, questNodes: QuestNode[]): Promise<QuestWithId> {
  try {
    const { data } = await questEditorApi.setQuestEditorQuestMetadataAndNodes(quest.questId, {
      metadata: mapQuestToApi(quest),
      nodes: questNodes,
    });

    return mapQuestFromApi(data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteQuestById(questId: string): Promise<void> {
  try {
    await questEditorApi.deleteQuestEditorQuest(questId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const questApi = new QuestApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function resetQuest(questId: string, userId: string): Promise<void> {
  try {
    await questApi.resetQuestForUser(userId, questId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function mapQuestFromApiWithoutData(quest: QuestMetadataWithQuestIdWithoutData): QuestWithId {
  return {
    ...quest,
    name: quest.displayName,
    data: undefined,
    folderId: quest.questEditorFolderId,
  };
}

export function mapQuestFromApi(quest: QuestMetadataWithQuestId): QuestWithId {
  return {
    ...quest,
    name: quest.displayName,
    data: typeof quest.questEditorData == "string" ? JSON.parse(quest.questEditorData) : quest.questEditorData,
    folderId: quest.questEditorFolderId,
  };
}

export function mapQuestToApi(quest: QuestWithId): QuestMetadataWithQuestId {
  const { name, data, folderId, ...questFields } = quest;

  return {
    ...questFields,
    displayName: name,
    questEditorData: JSON.stringify(data),
    questEditorDataDefinitions: JSON.stringify([]),
    questEditorFolderId: folderId,
  };
}
