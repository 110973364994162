import { Doc } from "yjs";
import { WebrtcProvider } from "y-webrtc";
import * as awarenessProtocol from "y-protocols/awareness";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router";
import { SessionProfile } from "../models/users/sessionProfile";

const doc = new Doc();

export interface User {
  userId: string | undefined;
  username: string;
  color: string;
  isCurrentUser: boolean;
}

export const useYDoc = (ulid?: string) => {
  const [activeUsers, setActiveUsers] = useState<User[]>([]);
  const {
    sessionProfile: { userId, displayName, displayNameColor },
  } = useLoaderData() as { sessionProfile: SessionProfile };

  if (ulid == null) {
    return {
      activeUsers,
    };
  }

  useEffect(() => {
    let provider: WebrtcProvider | undefined = undefined;

    try {
      provider = new WebrtcProvider(ulid, doc, {
        signaling: ["wss://editor-connections.dev.webb.party/ywrtc"],
        awareness: new awarenessProtocol.Awareness(doc),
        maxConns: 20 + Math.floor(Math.random() * 15),
        filterBcConns: true,
        peerOpts: {},
      });

      const { awareness } = provider;

      awareness.on("change", () => {
        setActiveUsers(
          Array.from(awareness.getStates()).map(([_, state]) => ({
            userId: state.user.userId,
            username: state.user.name,
            color: state.user.color,
            isCurrentUser: state.user.name === displayName,
          }))
        );
      });

      awareness.setLocalStateField("user", { userId, name: displayName, color: displayNameColor });
    } catch (error) {
      console.error(error);
    }

    return () => {
      provider?.destroy();
    };
  }, []);

  return {
    activeUsers,
  };
};
