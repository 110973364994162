import { Card, CardBody, Flex } from "@chakra-ui/react";
import { NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { useReactFlow } from "reactflow";
import { QuestWithId } from "../../../models/api/quest";
import { EdgeType } from "../../../models/edgeType";
import QuestStatusButton from "../../quests/QuestStatusButton";

interface NodeDeployPanelProps {
  quest?: QuestWithId;
}

function NodeDeployPanel({ quest }: NodeDeployPanelProps) {
  const { getNodes, setNodes, getEdges, setEdges } = useReactFlow<NodeType, EdgeType>();

  if (quest == null) {
    return null;
  }

  const { questId } = quest;

  if (questId == null) {
    return null;
  }

  return (
    <Flex position={"absolute"} top={"4.5rem"} right={"26rem"}>
      <Card outline={"none"} bg={"theme.dark.background"} p={1}>
        <CardBody bg={"whiteAlpha.200"} borderRadius={5} p={3}>
          <QuestStatusButton
            questId={questId}
            nodes={getNodes()}
            setNodes={setNodes}
            edges={getEdges()}
            setEdges={setEdges}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default NodeDeployPanel;
