import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { Start, StartNodeWithChildren } from "./StartNode";
import { Stack } from "@chakra-ui/react";
import { Location } from "../../../models/api/location";
import SelectLocation from "../../base/SelectLocation";

const StartIfPlayerAtLocationNode: React.FC<NodeProps<NodeType<Location & Start>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData: location },
  } = props;

  const reactFlow = useReactFlow();

  const handleUpdateLocation = useCallback(
    ({ roomName, x, y, radius }: Location) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as Location) ?? {};
        nodeData.roomName = roomName;
        nodeData.x = Number(x) || 0;
        nodeData.y = Number(y) || 0;
        nodeData.radius = Number(radius) || 0;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <StartNodeWithChildren {...props}>
      <Stack>
        <SelectLocation value={location} setValue={handleUpdateLocation} color={color} />
      </Stack>
    </StartNodeWithChildren>
  );
};

export default memo(StartIfPlayerAtLocationNode);
