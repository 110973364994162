import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { Start, StartNodeWithChildren } from "./StartNode";
import SelectNpc from "../../base/SelectNpc";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";

interface FormData extends Start {
  npcId: string;
  description: string;
}

const StartByChattingToNpcNode: React.FC<NodeProps<NodeType<FormData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const formData = nodeData as FormData | undefined;
  const npcId = formData?.npcId;
  const description = formData?.description;

  const { register, getValues, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      npcId,
      description,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ npcId, description }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.npcId = npcId;
        nodeData.description = description;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <StartNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack>
          <SelectNpc value={getValues("npcId")} setValue={(value) => setValue("npcId", value)} color={color} />
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Description for AI
              </Text>
            </FormLabel>
            <Textarea {...register("description")} color={color} />
          </FormControl>
        </Stack>
      </form>
    </StartNodeWithChildren>
  );
};

export default memo(StartByChattingToNpcNode);
